import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell convex, rugós o un poc bonyarrut de fins a 15-20 cm de diàmetre. Té coloració variable, pot passar del marró al marró groguenc, marró terrós o marró lleonat. Davall el capell trobem tubs llargs, porus petits i rodons, atapeïts, de color groc verd olivaci. El peu és obès, massís, després s’esdevé subcilíndric, més prim a la part superior i de coloració groc marronenca. Les espores són de color marró clar en massa, llises, fusiformes, de 12-22 x 5-7,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      